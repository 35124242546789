import Loader from "@core/ui/Loader";
import cn from "classnames";
import { ElementType, ForwardedRef, forwardRef } from "react";
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef,
} from "react-polymorphic-types";

export const ButtonDefaultElement = "button";

type Rounded = "default" | "lg" | "full";

type ButtonOwnProps = {
  color?:
    | "teal"
    | "dark-teal"
    | "red"
    | "gray"
    | "none"
    | "yellow"
    | "blue"
    | "indigo"
    | "light-gray"
    | "green";

  loading?: boolean;
  disablePadding?: boolean;
  disableMinHeight?: boolean;
  disableRadius?: boolean;
  loadingText?: string;
  rounded?: Rounded;
  variant?: "contained" | "outlined" | "text" | "ghost";
  type?: string;
};

export type Props<T extends ElementType = typeof ButtonDefaultElement> =
  PolymorphicPropsWithRef<ButtonOwnProps, T>;

const Button: PolymorphicForwardRefExoticComponent<
  ButtonOwnProps,
  typeof ButtonDefaultElement
> = forwardRef(
  <T extends ElementType = typeof ButtonDefaultElement>(
    {
      as,
      children,
      className,
      color = "teal",
      rounded = "default",
      variant = "contained",
      loading = false,
      type = "button",
      disablePadding = false,
      disableMinHeight = false,
      loadingText,
      ...rest
    }: PolymorphicPropsWithoutRef<ButtonOwnProps, T>,
    ref: ForwardedRef<T>
  ) => {
    const Component: ElementType = as || ButtonDefaultElement;
    const isDefaultElement = Component === ButtonDefaultElement;

    const loadingChild = loadingText ?? children;

    return (
      <Component
        ref={ref}
        type={isDefaultElement ? type : undefined}
        {...rest}
        className={cn(
          "overflow-visible text-center disabled:cursor-not-allowed",
          "transition-all duration-200 ease-in",
          {
            // Scale up height and horizontal padding based on font size
            "min-h-[2.5em]": variant !== "text" && !disableMinHeight,
            "px-[2.75em]": variant !== "text" && !disablePadding,
            "leading-none": variant !== "text",
            "leading-tight": variant === "text",

            // ***************
            // Color
            // ***************
            // Teal
            "border border-teal-600 bg-teal-600 text-white ring-offset-2 hover:border-teal-900 hover:bg-teal-900 focus:ring-2 focus:ring-teal-800 active:border-teal-900 active:bg-teal-900 disabled:opacity-30":
              color === "teal" && variant === "contained",
            "text-teal-600 hover:text-teal-900 focus:text-teal-800 disabled:opacity-30":
              color === "teal" && variant === "text",
            "border-2 border-teal-600 bg-white text-teal-600 hover:text-teal-700 focus:text-teal-700":
              color === "teal" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-teal-600 hover:bg-teal-200 focus:bg-teal-200":
              color === "teal" && variant === "ghost",

            // Dark Teal
            "border border-teal-800 bg-teal-800 text-white ring-offset-2 hover:border-teal-600 hover:bg-teal-600 focus:ring-2 focus:ring-teal-700 active:border-teal-600 active:bg-teal-600 disabled:opacity-30":
              color === "dark-teal" && variant === "contained",
            "text-teal-800 hover:text-teal-600 focus:text-teal-700 disabled:opacity-30":
              color === "dark-teal" && variant === "text",
            "border-2 border-teal-800 bg-white text-teal-800 hover:bg-teal-200 focus:bg-teal-200":
              color === "dark-teal" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-teal-800 hover:bg-teal-200 focus:bg-teal-200":
              color === "dark-teal" && variant === "ghost",

            // Gray
            "border border-gray-600 bg-gray-600 text-white ring-offset-2 hover:border-gray-900 hover:bg-gray-900 focus:ring-2 focus:ring-gray-800 active:border-gray-900 active:bg-gray-900 disabled:opacity-30":
              color === "gray" && variant === "contained",
            "text-gray-600 hover:text-gray-900  focus:text-gray-800 disabled:opacity-30":
              color === "gray" && variant === "text",
            "border-2 border-gray-600 bg-white text-gray-600 hover:bg-gray-200 focus:bg-gray-200":
              color === "gray" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-gray-600 hover:bg-gray-200 focus:bg-gray-200":
              color === "gray" && variant === "ghost",

            // Light Gray
            "border border-gray-100 bg-gray-100 text-gray-900 ring-offset-2 hover:border-gray-200 hover:bg-gray-200 focus:ring-2 focus:ring-gray-300 active:border-gray-200 active:bg-gray-200 disabled:opacity-30":
              color === "light-gray" && variant === "contained",
            "text-gray-400 hover:text-gray-900  focus:text-gray-600 disabled:opacity-30":
              color === "light-gray" && variant === "text",
            "border-2 border-gray-400 bg-white text-gray-400 hover:bg-gray-200 focus:bg-gray-200":
              color === "light-gray" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-gray-400 hover:bg-gray-200 focus:bg-gray-200":
              color === "light-gray" && variant === "ghost",

            // Red
            "border border-red-600 bg-red-600 text-white ring-offset-2 hover:border-red-900 hover:bg-red-900 focus:ring-2 focus:ring-red-800 active:border-teal-900  active:bg-red-900 disabled:opacity-30":
              color === "red" && variant === "contained",
            "text-red-600 hover:text-red-900  focus:text-red-800 disabled:opacity-30":
              color === "red" && variant === "text",
            "border-2 border-red-600 bg-white text-red-600 hover:bg-red-200 focus:bg-red-200":
              color === "red" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-red-600 hover:bg-red-200 focus:bg-red-200":
              color === "red" && variant === "ghost",

            // Yellow
            "active:bg-yellow-yellow border border-yellow-600 bg-yellow-600 text-white ring-offset-2 hover:border-yellow-900 hover:bg-yellow-900 focus:ring-2 focus:ring-yellow-800  active:border-teal-900 disabled:opacity-30":
              color === "yellow" && variant === "contained",
            "text-yellow-600 hover:text-yellow-900  focus:text-yellow-800 disabled:opacity-30":
              color === "yellow" && variant === "text",
            "border-2 border-yellow-600 bg-white text-yellow-600 hover:bg-yellow-200 focus:bg-yellow-200":
              color === "yellow" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-yellow-600 hover:bg-yellow-200 focus:bg-yellow-200":
              color === "yellow" && variant === "ghost",

            // Blue
            "hover:bg-blue-900 active:bg-blue-blue focus:ring-blue-800 hover:border-blue-900 border border-blue-600 bg-blue-600 text-white ring-offset-2 focus:ring-2  active:border-teal-900 disabled:opacity-30":
              color === "blue" && variant === "contained",
            "hover:text-blue-900 focus:text-blue-800  text-blue-600 disabled:opacity-30":
              color === "blue" && variant === "text",
            "border-2 border-blue-600 bg-white text-blue-600 hover:bg-blue-200 focus:bg-blue-200":
              color === "blue" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-blue-600 hover:bg-blue-200 focus:bg-blue-200":
              color === "blue" && variant === "ghost",

            // Indigo
            "active:bg-indigo-indigo border border-indigo-600 bg-indigo-600 text-white ring-offset-2 hover:border-indigo-900 hover:bg-indigo-900 focus:ring-2 focus:ring-indigo-800  active:border-teal-900 disabled:opacity-30":
              color === "indigo" && variant === "contained",
            "text-indigo-600 hover:text-indigo-900  focus:text-indigo-800 disabled:opacity-30":
              color === "indigo" && variant === "text",
            "border-2 border-indigo-600 bg-white text-indigo-600 hover:bg-indigo-200 focus:bg-indigo-200":
              color === "indigo" && variant === "outlined",
            "border-2 border-transparent bg-transparent text-indigo-600 hover:bg-indigo-200 focus:bg-indigo-200":
              color === "indigo" && variant === "ghost",

            /// Green
            "active:bg-#22C55E border border-#22C55E bg-#22C55E text-white ring-offset-2 hover:border-teal-900 focus:ring-2 focus:ring-teal-800 active:border-teal-900 active:bg-teal-900 disabled:opacity-30":
              color === "green" && variant === "contained",

            // ***************
            // Shape
            // ***************
            rounded: rounded === "default",
            "rounded-full": rounded === "full",
            "rounded-lg": rounded === "lg",
          },
          className
        )}
      >
        {loading ? (
          <div className="flex items-center justify-center space-x-2">
            <Loader />
            {loadingChild && <span>{loadingChild}</span>}
          </div>
        ) : (
          children
        )}
      </Component>
    );
  }
);

Button.displayName = "Button";

export default Button;
