import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import cn from "classnames";
import { ReactNode } from "react";

import Button from "../Button";

const DEFAULT_ICON_CLASSES = "w-6 h-6 flex-shrink-0";

type Severity = "info" | "warning" | "error" | "success";

export interface AlertProps {
  severity?: Severity;
  message: ReactNode;
  className?: string;
  callToAction?: {
    onClick: () => void;
    text: ReactNode;
  };
  title?: string;
}

const getButtonColorBySeverity = (severity: Severity) => {
  switch (severity) {
    case "warning":
      return "yellow";
    case "info":
      return "indigo";
    case "error":
      return "red";
    case "success":
      return "teal";
    default:
      throw new Error("Invalid severity");
  }
};

const Alert = ({
  severity = "info",
  message,
  callToAction,
  className,
  title,
}: AlertProps) => {
  return (
    <div
      className={cn(
        "flex w-full items-center space-x-2 rounded-lg",
        {
          "border border-yellow-300 bg-yellow-100": severity === "warning",
          "border border-indigo-300 bg-indigo-100": severity === "info",
          "border border-red-300 bg-red-100": severity === "error",
          "border border-teal-300 bg-teal-100": severity === "success",
        },
        className
      )}
    >
      <div
        className={cn("flex flex-grow p-4", { "flex-col": title && message })}
      >
        <div className="flex">
          {severity === "warning" && (
            <ExclamationIcon
              className={cn(DEFAULT_ICON_CLASSES, "text-yellow-800")}
            />
          )}

          {severity === "info" && (
            <InformationCircleIcon
              className={cn(DEFAULT_ICON_CLASSES, "text-indigo-800")}
            />
          )}

          {severity === "error" && (
            <XCircleIcon className={cn(DEFAULT_ICON_CLASSES, "text-red-800")} />
          )}

          {severity === "success" && (
            <CheckCircleIcon
              className={cn(DEFAULT_ICON_CLASSES, "text-teal-800")}
            />
          )}

          {title && (
            <p
              className={cn("text-16px font-bold ml-1", {
                "text-yellow-800": severity === "warning",
                "text-indigo-800": severity === "info",
                "text-red-800": severity === "error",
                "text-teal-800": severity === "success",
              })}
            >
              {title}
            </p>
          )}
        </div>

        <div
          className={cn("flex-1 tablet:flex tablet:justify-between", {
            "ml-3": !title,
          })}
        >
          <p
            className={cn("text-16px", {
              "text-yellow-900": severity === "warning",
              "text-indigo-900": severity === "info",
              "text-red-900": severity === "error",
              "text-teal-900": severity === "success",
              block: !!title,
            })}
          >
            {message}
          </p>

          {callToAction?.onClick && callToAction?.text && (
            <Button
              onClick={callToAction.onClick}
              color={getButtonColorBySeverity(severity)}
              className={cn("mt-2 text-16px font-bold tablet:mt-0", {
                "!text-teal-800 hover:!text-teal-900": severity === "success",
                "!text-red-800 hover:!text-red-900": severity === "error",
                "!text-indigo-800 hover:!text-indigo-900": severity === "info",
                "!text-yellow-800 hover:!text-yellow-900":
                  severity === "warning",
              })}
              variant="text"
            >
              {callToAction.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
