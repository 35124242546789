import { useSession } from "@core/hooks/useSession";
import useUser, { LinkedAccount, User } from "@core/hooks/useUser";
import {
  api,
  AppointmentTypes,
  MemberProtocolTypes,
} from "@core/services/nocd-api";
import { InsuranceCardUploadStatus } from "@core/types";
import {
  BillingInfo,
  useBillingInfo,
} from "@features/billing/hooks/useBillingInfo";
import { compareDesc } from "date-fns";
import { isValid, parseISO } from "date-fns/fp";
import { isNull, mapValues } from "lodash/fp";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import { JournalEntry, Status, Task, TaskOverview } from "../types";

export enum TherapyHomeCallTypes {
  INTAKE_CALL = "Intake call",
  THERAPY_SESSION = "Therapy session",
}

interface TherapyHomeAppointmentDTO {
  id: number | string;
  call_type: TherapyHomeCallTypes;
  datetime: string;
  host: {
    name: string;
    url: string;
    transfer_url: string;
    schedule_url: string;
    review_url: string;
    image_id: string;
    user_id: number;
  };
  meeting_url: string;
  session_length: number;
  todos: TaskDto[];
  appointment_type: AppointmentTypes;
  reschedule_url: string;
  canceled: boolean;
}

export interface TherapyHomeAppointment {
  id: number | string;
  datetime: Date;
  durationInMinutes: number;
  host: {
    name: string;
    url: string;
    scheduleUrl: string;
    transferUrl: string;
    reviewUrl: string;
    userId: number;
    imageId: string;
  };
  meetingUrl: string;
  todos: Task[];
  type: TherapyHomeCallTypes;
  rescheduleUrl: string;
  appointmentType: AppointmentTypes;
  canceled: boolean;
}

interface TherapyHomeMemberDto {
  user_id: number;
  guid: string;
  diagnostic_timezone: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  protocol_type: MemberProtocolTypes;
  email?: string;
  current_scheduling_frequency?: string;
}

interface TaskDto {
  id: string;
  description: string;
  name: string;
  due_date: string;
  appointment_type: AppointmentTypes;
  show_after_due_date?: boolean;
}

interface TaskOverviewDTO {
  overdue: TaskDto[];
  by_due_date: { [key: string]: TaskDto[] };
  no_due_date: TaskDto[];
  total_outstanding_tasks: number;
}

interface BillingTodo {
  task_id: string;
  is_complete: boolean;
}
interface Response {
  clinicians: TherapyHomeClinicianDto[];
  clinician: TherapyHomeClinicianDto;
  upcoming_appointments: TherapyHomeAppointmentDTO[];
  past_appointments: TherapyHomeAppointmentDTO[];
  member: TherapyHomeMemberDto;
  session_url: string;
  task_overview: TaskOverviewDTO;
  journal_entries: JournalEntry[];
  has_hierarchy: boolean;
  is_form_automation_enabled: boolean;
  needs_insurance_upload: boolean;
  has_completed_therapy_consent_form: boolean;
  has_completed_fear_response_list: boolean;
  has_active_homework_plan: boolean;
  fears_and_responses_enabled: boolean;
  linked_account_homes?: Response[];
  billing_todos: BillingTodo[];
  unread_messages_count: number;
  should_see_welcome_banner: boolean;
  has_upcoming_intake_call: boolean;
  can_transfer_therapist: boolean;
  can_reschedule_session: boolean;
  can_schedule_session: boolean;
  insurance_card_upload_status: InsuranceCardUploadStatus | null;
  sched_protocol_compliant: boolean;
  scheduling_frequency: {
    full: string;
    quantity: number;
    unit: string;
  } | null;
  enable_multi_booking: boolean;
  canceled_appointment: TherapyHomeAppointmentDTO | null;
  conqueror_advocacy: {
    share_flow_url: string;
    number_of_shares: number | null;
  };
  had_assigned_clinician: boolean;
  is_ocd_conqueror: boolean;
  is_active: boolean;
}

export interface TherapyHomeClinician {
  userId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  title: string;
  scheduleUrl: string;
  transferUrl: string;
  profileUrl: string;
  reviewUrl: string;
  imageId: string;
  imageUrl: string;
}

interface TherapyHomeClinicianDto {
  first_name: string;
  last_name: string;
  title: string;
  nickname: string;
  user_id: number;
  image_id: string;
  image: string;
  schedule_url: string;
  transfer_url: string;
  profile_url: string;
  review_url: string;
}

const transformClinicianDto = (
  dto: TherapyHomeClinicianDto
): TherapyHomeClinician => ({
  userId: dto.user_id,
  firstName: dto.first_name,
  lastName: dto.last_name,
  fullName: dto.nickname || `${dto.first_name} ${dto.last_name}`,
  title: dto.title,
  transferUrl: dto.transfer_url,
  scheduleUrl: dto.schedule_url,
  imageId: dto.image_id,
  imageUrl: dto.image,
  profileUrl: dto.profile_url,
  reviewUrl: dto.review_url,
});

export const transformTaskDTO = (dto: TaskDto): Task => {
  const parsedDueDate = dto.due_date ? new Date(dto.due_date) : null;

  return {
    id: dto.id,
    // Task names come back in snake_case from the API. However, our page
    // routes use kebab-case.
    name: dto.name.split("_").join("-"),
    description: dto.description,
    dueDate: isValid(parsedDueDate) ? parsedDueDate : null,
    appointmentType: dto.appointment_type,
  };
};

export const transformAppointmentDTO = (
  dto: TherapyHomeAppointmentDTO
): TherapyHomeAppointment => {
  const parsedDateTime = parseISO(dto.datetime);

  return {
    id: dto.id,
    datetime: isValid(parsedDateTime) ? parsedDateTime : null,
    durationInMinutes: dto.session_length,
    host: {
      name: dto.host.name,
      url: dto.host.url,
      transferUrl: dto.host.transfer_url,
      scheduleUrl: dto.host.schedule_url,
      reviewUrl: dto.host.review_url,
      userId: dto.host.user_id,
      imageId: dto.host.image_id,
    },
    meetingUrl: dto.meeting_url,
    todos: (dto.todos ?? []).map((todo) => transformTaskDTO(todo)),
    type: dto.call_type,
    appointmentType: dto.appointment_type,
    rescheduleUrl: dto.reschedule_url,
    canceled: dto?.canceled,
  };
};

export interface TherapyHomeMember {
  userId: number;
  guid: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  timeZone: string;
  protocolType: MemberProtocolTypes;
  email?: string;
  currentSchedulingFrequency?: string;
}

export const transformMemberDTO = (
  dto: TherapyHomeMemberDto
): TherapyHomeMember => {
  return {
    userId: dto.user_id,
    guid: dto.guid,
    firstName: dto.first_name,
    lastName: dto.last_name,
    preferredName: dto.preferred_name,
    timeZone: dto.diagnostic_timezone,
    protocolType: dto.protocol_type,
    email: dto.email,
    currentSchedulingFrequency: dto.current_scheduling_frequency,
  };
};

interface CancelledAppointment extends TherapyHomeAppointment {
  canceled: true;
}

export interface TherapyHomeData {
  clinician: TherapyHomeClinician;
  clinicians: TherapyHomeClinician[];
  member: TherapyHomeMember;
  is_active: boolean;
  taskOverview: TaskOverview;
  upcomingAppointments: TherapyHomeAppointment[];
  pastAppointments: TherapyHomeAppointment[];
  journalEntries: JournalEntry[];
  hasHierarchy: boolean;
  hasCompletedTherapyConsent: boolean;
  hasCompletedFearResponseList: boolean;
  hasFearsAndResponsesEnabled: boolean;
  hasActiveHomeworkPlan: boolean;
  status: Status;
  linkedAccountHomes: TherapyHomeData[] | null;
  billingTodos: BillingTodo[];
  unreadMessagesCount: number;
  shouldSeeTherapyWelcomeBanner: boolean;
  hasUpcomingIntakeCall: boolean;
  canTransferTherapist: boolean;
  canRescheduleSession: boolean;
  canScheduleSession: boolean;
  insuranceCardUploadStatus: InsuranceCardUploadStatus | null;
  schedulingFrequency: {
    full: string;
    quantity: number;
    unit: string;
  } | null;
  schedulingProtocolCompliant: boolean;
  enableMultiBooking: boolean;
  cancelledAppointment: CancelledAppointment | null;
  conquerorAdvocacy: {
    share_flow_url: string;
    number_of_shares: number;
  } | null;
  hadAssignedClinician: boolean;
  isOcdConqueror: boolean;
}

export const getQueryKey = (accessToken: string): string[] => [
  "members-home",
  accessToken,
];

// We naively pass through the task name and route to a page based on it. To
// make sure we don't accidentally send the member to a page that doesn't exist,
// we explicitly list the tasks that we have UI to support.
const SUPPORTED_TASKS = [
  "cir",
  "consent",
  "dass-21",
  "diamond-adolescent",
  "diamond-adult",
  "diamond-child",
  "docs",
  "hrs",
  "journal",
  "qles",
  "rbfbs",
  "roi",
  "tic",
  "upload-insurance",
  "whodas2",
  "notice-of-privacy-practices",
  "intake-questionnaire",
  "privacy-and-consent",
  "pcl5",
  "linked-roi",
  "411-privacy-and-consent",
  "ocicvr-consent",
  "oci-cv-r",
  "upload-parental-agreement",
  "payment-plan-agreement",
  "abn-consent",
  "phq-9",
  "gad-7",
  "asi-3",
  "lsas",
  "smspa",
  "shai",
  "smaa",
  "smsada",
  "biq",
  "fas",
  "mfq",
  "pswq-c",
  "lsas-ca",
  "p-qles-q",
  "dass-y",
  "making-time-for-ocd-treatment",
];

function transformResponseIntoTherapyHomeData({
  data,
}: {
  data: Response;
}): TherapyHomeData {
  return {
    clinicians: (data.clinicians ?? []).map(transformClinicianDto),
    clinician: data.clinician ? transformClinicianDto(data.clinician) : null,
    member: transformMemberDTO(data.member),
    taskOverview: {
      overdue: data.task_overview.overdue
        .map(transformTaskDTO)
        .filter(({ name }) => SUPPORTED_TASKS.includes(name)),
      byDueDate: mapValues(
        (taskArray) =>
          taskArray
            .map(transformTaskDTO)
            .filter(({ name }) => SUPPORTED_TASKS.includes(name)),
        data.task_overview.by_due_date
      ),
      noDueDate: data.task_overview.no_due_date
        .map(transformTaskDTO)
        .filter(({ name }) => SUPPORTED_TASKS.includes(name)),
      totalOutstandingTasks: data.task_overview.total_outstanding_tasks,
    },
    upcomingAppointments: data.upcoming_appointments
      .map(transformAppointmentDTO)
      .filter(({ datetime }) => !isNull(datetime)),
    pastAppointments: (data.past_appointments ?? [])
      .map(transformAppointmentDTO)
      .filter(({ datetime }) => !isNull(datetime)),
    journalEntries: data.journal_entries
      .slice()
      .sort((a, b) =>
        compareDesc(new Date(a.created_at), new Date(b.created_at))
      ),
    hasHierarchy: data.has_hierarchy,
    status: {},
    hasCompletedTherapyConsent: !!data.has_completed_therapy_consent_form,
    hasCompletedFearResponseList: !!data.has_completed_fear_response_list,
    hasFearsAndResponsesEnabled: !!data.fears_and_responses_enabled,
    hasActiveHomeworkPlan: !!data.has_active_homework_plan,
    // just a little light recursion
    linkedAccountHomes: data?.linked_account_homes
      ? data.linked_account_homes.map((la_home) =>
          transformResponseIntoTherapyHomeData({ data: la_home })
        )
      : null,
    billingTodos: data.billing_todos,
    unreadMessagesCount: data.unread_messages_count,
    shouldSeeTherapyWelcomeBanner: data.should_see_welcome_banner,
    hasUpcomingIntakeCall: data.has_upcoming_intake_call,
    canTransferTherapist: data.can_transfer_therapist,
    canRescheduleSession: data.can_reschedule_session,
    canScheduleSession: data.can_schedule_session,
    insuranceCardUploadStatus: data.insurance_card_upload_status,
    schedulingFrequency: data.scheduling_frequency,
    schedulingProtocolCompliant: data.sched_protocol_compliant,
    enableMultiBooking: data.enable_multi_booking,
    cancelledAppointment: data.canceled_appointment
      ? (transformAppointmentDTO(
          data.canceled_appointment
        ) as CancelledAppointment)
      : null,
    conquerorAdvocacy: data?.conqueror_advocacy,
    hadAssignedClinician: data?.had_assigned_clinician,
    isOcdConqueror: data?.is_ocd_conqueror,
    is_active: data?.is_active,
  };
}

export const useTherapyHomeData = (
  options: UseQueryOptions<undefined, Error, TherapyHomeData> = {}
): UseQueryResult<TherapyHomeData, Error> => {
  const { data: session } = useSession();

  return useQuery(
    getQueryKey(session?.accessToken),
    ({ signal }): Promise<TherapyHomeData> =>
      api
        .get<Response>("/v2/members/home", {
          headers: {
            Authorization: session?.accessToken,
            "X-Platform": "web",
          },
          signal,
        })
        .then((data) => transformResponseIntoTherapyHomeData(data)),
    { enabled: !!session?.accessToken, ...options }
  );
};

export interface ZippedData {
  linkedAccount: LinkedAccount;
  therapyHome: TherapyHomeData;
  billingInfo: BillingInfo;
}

function zipMemberData(
  user: User,
  therapyHome: TherapyHomeData,
  billingInfo: BillingInfo
): ZippedData[] {
  if (!user || !therapyHome || !billingInfo) return [];

  const result: {
    linkedAccount: LinkedAccount;
    therapyHome: TherapyHomeData;
    billingInfo: BillingInfo;
  }[] = [];
  // currently logged in user
  result.push({
    linkedAccount: user?.linkedAccounts?.find((x) => x.user_id === user.id),
    therapyHome,
    billingInfo,
  });

  // linked accounts
  therapyHome?.linkedAccountHomes?.forEach((laHome) => {
    result.push({
      linkedAccount: user?.linkedAccounts?.find(
        (la) => la.user_id === laHome.member.userId
      ),
      therapyHome: laHome,
      billingInfo: billingInfo.linkedBillingInfo?.find(
        (lb) => lb.userId === laHome.member.userId
      ),
    });
  });

  return result;
}

export const useZippedData = () => {
  const { data: therapyHomeData, isLoading: isLoadingTherapyHomeData } =
    useTherapyHomeData();

  const { data: user, isLoading: isLoadingUser } = useUser();

  const { data: billingInfo, isLoading: isLoadingBillingInfo } =
    useBillingInfo();

  return {
    isLoading:
      isLoadingTherapyHomeData || isLoadingUser || isLoadingBillingInfo,
    data: zipMemberData(user, therapyHomeData, billingInfo),
  };
};
