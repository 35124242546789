export enum FeedTypes {
  ALL_TOPICS = "all_topics",
  MY_TOPICS = "my_topics",
}

export enum FeedContentFilters {
  NOCD = "nocd",
  NO_TRIGGER_WARNING = "no_trigger_warning",
}

export enum PostAvatarTypes {
  THERAPIST = "nocd_pro",
  DEFAULT = "legacy",
  ADVOCATE = "nocd_advocate",
}

export enum PostTypes {
  HTML = "html",
  IFRAME = "iframe",
  IMAGE_V1 = "image_v1",
  LINK = "link",
  TEXT = "text",
  TEXT_REPLY = "text_reply",
  UNKNOWN = "unknown",
  VIDEO = "video",
  THERAPY_SESSION_REVIEW = "therapy_session_review",
  MULTI_IMAGE = "image_v2",
}

export enum PostPositionTypes {
  DYNAMIC_MODULE = "DYNAMIC_MODULE",
  PINNED = "PINNED",
  TRADITIONAL = "TRADITIONAL",
}

export enum PostFeedPlacements {
  MAIN = "MAIN",
  MAIN_AND_FEATURED = "MAIN_AND_FEATURED",
}

export enum PostFlagStates {
  MODERATOR_APPROVED = "moderator_approved",
  MODERATOR_SPAM = "moderator_spam",
  MODERATOR_REASSURANCE_SEEKING = "moderator_reassurance_seeking",
  MODERATOR_REASSURANCE_PROVIDING = "moderator_reassurance_providing",
  MODERATOR_OFFENSIVE = "moderator_offensive",
  MODERATOR_SELF_HARM_SUICIDE = "moderator_self_harm_suicide",
  USER_SPAM = "user_spam",
  USER_OFFENSIVE = "user_offensive",
  USER_REASSURANCE = "user_reassurance",
  USER_SELF_HARM_SUICIDE = "user_self_harm_suicide",
}

export interface PostTopic {
  topic_id: string;
  topic_title: string;
  slug: string;
}

export interface Post {
  accepted_reassurance_pledge: boolean;
  advocate_chat_enabled: boolean;
  avatar_handle: string;
  avatar_img: string;
  avatar_name: string;
  avatar_type: PostAvatarTypes;
  avatar_url: string;
  badge: {
    text: string;
    text_color_hex: string;
    background_color_hex: string;
    image_url: string;
  };
  nocd_handle: string;
  nocd_handle_color_hex: string;
  body: string;
  community_handle: string;
  community_handle_badge: {
    icon_url_svg: string;
    icon_url_png: string;
    icon_aspect_ratio: number;
  } | null;
  created_at: string;
  created_by_moderator: number;
  current_user_replied_on_entry: boolean;
  current_users_post: boolean;
  cycled: number;
  db_depth: number;
  dm_depth: number;
  dm_day: number;
  dm_end_at: string;
  dm_rank: number;
  dm_start_at: string;
  dm_tag: string;
  dynamic_text: string;
  fallback_body: string;
  feed_placement: PostFeedPlacements;
  feed_placement_src: string;
  flag_state: PostFlagStates;
  geo_locked: boolean;
  human_timestamp: string;
  id: number;
  inactive_reason: string;
  isBookmarked: boolean;
  is_active: number;
  is_deleted: number;
  is_liked: boolean;
  is_locked: boolean;
  is_muted: boolean;
  is_visible: boolean;
  likes: number;
  lock_status: string;
  name: string;
  num_replies: number;
  pinned: boolean;
  position_type: PostPositionTypes;
  post_replied_to: number;
  post_type: PostTypes;
  post_type_data: {
    post_id: number;
    aspect_ratio: number;
    image_public_id: string;
    image_url: string;
    url: string;
    url_type: string;
    vimeo_video_link: string;
    link: string;
    title: string;
    description: string;
    cta_url: string;
    cta_icon_image_url_svg: string;
    cta_icon_image_url_png: string;
    cta_icon_image_aspect_ratio: number;
    badge_icon_image_url_svg: string;
    badge_icon_image_url_png: string;
    badge_icon_image_aspect_ratio: number;
    badge_text: string;
    images?: Array<{
      url: string;
      aspect_ratio: number;
      image_public_id: string;
    }>;
  };
  render_html: boolean;
  replies: Post[];
  reviewed: number;
  reviewed_by: string;
  seen_id: string;
  sql_filter: string;
  thread_id: number;
  topics: PostTopic[];
  trigger_warning: boolean;
  updated_at: string;
  user_id: string;
  user_id_mod_10_filter: string;
  view_period: string;
  is_therapy_member: boolean;
  seo_title?: string;
  seo_description?: string;
  posting_title?: string;
}

interface TopicDto {
  about?: string;
  about_url?: string;
  category: string;
  created_at: string;
  description?: string;
  id: string;
  image_url?: string;
  is_followed?: boolean;
  ordering: number;
  title: string;
  updated_at: string;
}

export interface Topic {
  about?: string;
  aboutUrl?: string;
  category: string;
  createdAt: string;
  description?: string;
  id: string;
  imageUrl?: string;
  isFollowed?: boolean;
  ordering: number;
  title: string;
  updatedAt: string;
}

export interface CategorizedTopicsDto {
  community_filters: TopicDto[];
  subtypes: TopicDto[];
}

export interface CategorizedTopics {
  communityFilters: Topic[];
  subtypes: Topic[];
}

export type TopicExt = Topic & { slug: string };

export interface PostExt {
  accepted_reassurance_pledge: boolean;
  advocate_chat_enabled: boolean;
  avatar_handle: string;
  avatar_img: string;
  avatar_name: string;
  avatar_type: PostAvatarTypes;
  avatar_url: string;
  badge: {
    text: string;
    text_color_hex: string;
    background_color_hex: string;
    image_url: string;
  };
  nocd_handle: string;
  nocd_handle_color_hex: string;
  body: string;
  community_handle: string;
  community_handle_badge: {
    icon_url_svg: string;
    icon_url_png: string;
    icon_aspect_ratio: number;
  } | null;
  created_at: string;
  created_by_moderator: number;
  current_user_replied_on_entry: boolean;
  current_users_post: boolean;
  cycled: number;
  db_depth: number;
  dm_depth: number;
  dm_day: number;
  dm_end_at: string;
  dm_rank: number;
  dm_start_at: string;
  dm_tag: string;
  dynamic_text: string;
  fallback_body: string;
  feed_placement: PostFeedPlacements;
  feed_placement_src: string;
  flag_state: PostFlagStates;
  geo_locked: boolean;
  human_timestamp: string;
  id: number;
  inactive_reason: string;
  isBookmarked: boolean;
  is_active: number;
  is_deleted: number;
  is_liked: boolean;
  is_locked: boolean;
  is_muted: boolean;
  is_visible: boolean;
  likes: number;
  lock_status: string;
  name: string;
  num_replies: number;
  pinned: boolean;
  position_type: PostPositionTypes;
  post_replied_to: number;
  post_type: PostTypes;
  post_type_data: {
    post_id: number;
    aspect_ratio: number;
    image_public_id: string;
    image_url: string;
    url: string;
    url_type: string;
    vimeo_video_link: string;
    link: string;
    title: string;
    description: string;
    cta_url: string;
    cta_icon_image_url_svg: string;
    cta_icon_image_url_png: string;
    cta_icon_image_aspect_ratio: number;
    badge_icon_image_url_svg: string;
    badge_icon_image_url_png: string;
    badge_icon_image_aspect_ratio: number;
    badge_text: string;
    images?: Array<{
      url: string;
      aspect_ratio: number;
      image_public_id: string;
    }>;
  };
  render_html: boolean;
  replies: PostExt[];
  reviewed: number;
  reviewed_by: string;
  seen_id: string;
  sql_filter: string;
  thread_id: number;
  topics: PostTopic[];
  trigger_warning: boolean;
  updated_at: string;
  user_id: string;
  user_id_mod_10_filter: string;
  view_period: string;
  is_therapy_member: boolean;
  seo_title?: string;
  seo_description?: string;
  posting_title?: string;
}

export interface ApiPaginatedPosts {
  cursors: {
    count: number;
    current: string;
    next: string;
    prev: string;
  };
  data: Post[];
  layout_id: string;
  refresh_required: boolean;
}

export interface PaginatedPosts {
  cursors: {
    count: number;
    current: string;
    next: string;
    prev: string;
  };
  data: PostExt[];
  layout_id: string;
  refresh_required: boolean;
}

export enum PostActionIds {
  BLOCK_USER = "Block User",
  DELETE_POST = "Delete post",
  FLAG_POST = "Flag post",
  FOLLOW_USER = "Follow User",
  HIDE_POST = "Hide post",
  LIKE_POST = "Like post",
  MUTE_NOTIFICATIONS = "Mute Notifications",
  BOOKMARK_POST = "Bookmark post",
  UNBOOKMARK_POST = "Unbookmark post",
}

export interface PostAction {
  description: string;
  enabled: boolean;
  id: PostActionIds;
  image_url: string;
  link: string;
  post_id: number;
  refresh: boolean;
  success_message: string;
  title: string;
  toast_success_description: string;
  toast_success_title: string;
}

export enum PostAvatarSizes {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

/**
 * The ID of the post at depth 0. This is needed when performing operations
 * on post replies.
 */
export type RootPostId = string | number;

export type PostId = string | number;
