import "nprogress/nprogress.css";
import "../styles/globals.css";
import "intersection-observer";
import "@ungap/global-this";

import useCanGoBack from "@core/hooks/useCanGoBack";
import { IsAuthenticatingProvider } from "@core/hooks/useIsAuthenticating";
import AppShell from "@core/layout/AppShell";
import type { Page } from "@core/types";
import { RouterScrollProvider } from "@moxy/next-router-scroll";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import LogRocket from "logrocket";
import { NextPage } from "next";
import type { AppProps as NextAppProps } from "next/app";
import { useRouter } from "next/router";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import NProgress from "nprogress";
import { ComponentType, ReactElement, useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { Hydrate } from "react-query/hydration";

NProgress.configure({ showSpinner: false });

if (
  typeof window !== "undefined" &&
  process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID &&
  process.env.NODE_ENV === "production"
) {
  setTimeout(() => {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID);
  }, 10000);
}

interface PageProps {
  dehydratedState: unknown;
  session?: Session;
  err?: Error;
}

type AppProps = Omit<NextAppProps<PageProps>, "Component" | "pageProps"> & {
  Component: NextPage<PageProps> & {
    hideNavBar?: Page["hideNavBar"];
    hideNavBarOnMobile?: Page["hideNavBarOnMobile"];
    shouldShowConsentDialog?: Page["shouldShowConsentDialog"];
    backgroundColor?: Page["backgroundColor"];
    hideNavbarShadowOnMobile?: Page["hideNavbarShadowOnMobile"];
  };
  pageProps: PageProps;
  err?: Error;
};

type SetTimeoutReturn = ReturnType<typeof setTimeout>;

const App = ({ Component, pageProps, err }: AppProps): ReactElement => {
  const router = useRouter();
  const { setCurrentPath } = useCanGoBack();

  // By default, when a user navigates to a page that is server-side rendered,
  // there is no indication that a page is loading. To improve UX, we use
  // a library called `nprogress` to display a small loading indicator at the
  // top of the page when a route changes.
  useEffect(() => {
    const delayInMilliseconds = 500;

    let timer: SetTimeoutReturn;

    const startProgressBar = () => {
      timer = setTimeout(() => {
        NProgress.start();
      }, delayInMilliseconds);
    };

    const finishProgressBar = () => {
      clearTimeout(timer);
      NProgress.done();
    };

    router.events.on("routeChangeStart", () => startProgressBar());
    router.events.on("routeChangeComplete", () => finishProgressBar());
    router.events.on("routeChangeError", () => finishProgressBar());

    return () => {
      router.events.off("routeChangeStart", startProgressBar);
      router.events.off("routeChangeComplete", finishProgressBar);
      router.events.off("routeChangeError", finishProgressBar);
    };
  }, [router.events]);

  useEffect(() => {
    setCurrentPath(router.asPath);
  }, [router.asPath, setCurrentPath]);

  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  const {
    hideNavBar = false,
    hideNavBarOnMobile = false,
    backgroundColor,
    shouldShowConsentDialog = false,
    hideNavbarShadowOnMobile = false,
  } = Component;

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#01959F",
              secondary: "#FFF",
            },
          },
          error: {
            iconTheme: {
              primary: "#E9503B",
              secondary: "#FFF",
            },
          },
          loading: {
            iconTheme: {
              primary: "#0065FF",
              secondary: "#FFF",
            },
          },
          blank: {
            iconTheme: {
              primary: "#0065FF",
              secondary: "#FFF",
            },
          },
        }}
      />

      <DefaultSeo
        title="Online OCD Treatment &amp; Therapy"
        titleTemplate="%s / NOCD"
        dangerouslySetAllPagesToNoIndex
        dangerouslySetAllPagesToNoFollow
        openGraph={{
          type: "website",
          locale: "en_US",
          site_name: "NOCD",
          url: process.env.NEXT_PUBLIC_CANONICAL_URL,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_CANONICAL_URL}/images/meta-image.png`,
            },
          ],
        }}
        twitter={{
          handle: "@treatmyocd",
          site: "@treatmyocd",
          cardType: "summary_large_image",
        }}
        additionalLinkTags={[
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon-32x32.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon-16x16.png",
          },
          {
            rel: "manifest",
            href: "/site.webmanifest",
          },
          {
            rel: "mask-icon",
            href: "/safari-pinned-tab.svg",
            color: "#00a3ad",
          },
        ]}
        additionalMetaTags={[
          {
            name: "msapplication-TileColor",
            content: "#00a3ad",
          },
          {
            name: "theme-color",
            content: "#ffffff",
          },
          {
            name: "facebook-domain-verification",
            content: "jijnvn52abvys5k5tdztooul0ti55b",
          },
          {
            name: "viewport",
            content: "initial-scale=1.0, width=device-width",
          },
          // // Meta tag to associate the member portal with the NOCD app.
          // // If the user is on an iOS device, they'll be asked to launch
          // // or download the NOCD app.
          // hideNavBar
          //   ? null
          //   : { name: "apple-itunes-app", content: "app-id=1063365447" },
        ].filter(Boolean)}
      />
      <RouterScrollProvider>
        <SessionProvider session={pageProps.session}>
          <QueryClientProvider client={queryClientRef.current}>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <Hydrate state={pageProps.dehydratedState}>
              <IsAuthenticatingProvider>
                <AppShell
                  hideNavBar={hideNavBar}
                  backgroundColor={backgroundColor}
                  shouldShowConsentDialog={shouldShowConsentDialog}
                  hideNavBarOnMobile={hideNavBarOnMobile}
                  hideNavbarShadowOnMobile={hideNavbarShadowOnMobile}
                  {...pageProps}
                >
                  <Component {...pageProps} err={err} />
                </AppShell>
              </IsAuthenticatingProvider>
            </Hydrate>
          </QueryClientProvider>
        </SessionProvider>
      </RouterScrollProvider>
    </>
  );
};

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LD_CLIENT_SIDE_ID,
  // The user starts out anonymous. At the time of writing, we identify the user
  // later in core/layout/SideEffects.tsx (if they are authenticated).
  user: {
    // !!! IMPORTANT !!!
    // We hard-code the key here, so that all anonymous users only count as
    // one monthly active user.
    key: "anonymous",
    anonymous: true,
  },
  options: {
    bootstrap: "localStorage",
  },
})(App as ComponentType);
